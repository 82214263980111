import React from 'react'
import DiscordBot from '../assets/discord.png'
import RasPi from '../assets/rasPi.png'
import DartGame from '../assets/dartgame.png'

const work = () => {
  return (
    <div name='work' className='=w-full md:h-screen text-gray-300 bg-[#0a192f]'>
        <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
            <div className='pb-8'>
                <p className='text-4xl font-bold inline border-b-4 text-gray-300 border-blue-400'>Work</p>
                <p className='py-6'>// Check out some of my recent work</p>
            </div>

            {/*Container */}
            <div
            className='grid sm:grid-cols-2 md:grid-cols-3 gap-4'>
                
                {/*grid item*/}
                <div 
                style={{backgroundImage: `url(${DiscordBot}`, backgroundSize: 450}} className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center mx-auto content-div'>
                    
                    {/*Hover*/}
                    <div className='opacity-0 group-hover:opacity-100'>
                        <span className='text-center text-2xl font-bold text-white tracking-wider'>
                            DiscordBot Template
                        </span>
                        <div className='pt-8 text-center'>
                            <a href="https://github.com/cwegzi/discordbot/blob/main/DiscordBot.py">
                                <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Code</button>
                            </a>

                        </div>
                    </div>
                </div>

                <div 
                style={{backgroundImage: `url(${RasPi}`}} className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center mx-auto content-div'>
                    
                    {/*Hover*/}
                    <div className='opacity-0 group-hover:opacity-100'>
                        <span className='text-center text-2xl font-bold text-white tracking-wider'>
                            RaspberryPi GUI
                        </span>
                        <div className='pt-8 text-center'>
                            <a href="https://github.com/cwegzi/projects/blob/main/raspiGUI.py">
                                <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Code</button>
                            </a>

                        </div>
                    </div>
                </div>

                <div 
                style={{backgroundImage: `url(${DartGame}`, backgroundSize: 450}} className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center mx-auto content-div'>
                    
                    {/*Hover*/}
                    <div className='opacity-0 group-hover:opacity-100'>
                        <span className=' text-center text-2xl font-bold text-white tracking-wider'>
                            Text Based Python Dart
                            Game
                        </span>
                        <div className='pt-8 text-center'>
                            <a href="https://github.com/cwegzi/projects/blob/main/dartgame_final">
                                <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Code</button>
                            </a>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default work